import React from 'react';
import { Animator } from '@arwes/react-animator';
import { GridLines, Dots, MovingLines, Puffs } from '@arwes/react-bgs';
import '../styles.css';
import ship1 from '../images/ship1.png';
import ship2 from '../images/ship2.png';
import { FrameSVGNefrex } from '@arwes/react-frames';

const Sandbox = () => {
  return (
    <Animator active='true' duration={{ interval: 10 }}>
      <div style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#87CEFA',
        backgroundImage: 'radial-gradient(85% 85% at 50% 50%, hsla(185, 100%, 25%, 0.25) 0%, hsla(185, 100%, 25%, 0.12) 50%, hsla(185, 100%, 25%, 0) 100%)'
      }}>
        <GridLines
          lineColor='hsla(180, 100%, 75%, 0.05)'
          distance={30}
        />
        <Dots
          color='hsla(180, 100%, 75%, 0.05)'
          distance={30}
        />
        <MovingLines
          lineColor='hsla(180, 100%, 75%, 0.07)'
          distance={30}
          sets={20}
        />
        <Puffs
          color='hsla(200, 100%, 75%, 0.5)'
          quantity={500}
          padding={20}
          xOffset={[50, -100]}
          yOffset={[50, -100]}
          radiusOffset={[2, 0]}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img className="ship ship1" src={ship1} alt="Ship 1"></img>
          <img className="ship ship2" src={ship2} alt="Ship 2"></img>
        </div>
      </div>
    </Animator>
  );
};

export default Sandbox;
