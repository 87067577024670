
import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';
import Sandbox from './background/index';

function App() {
  createRoot(document.querySelector('#root')).render(
    <div>
      <Sandbox />
    </div>
  );
}

export default App;
